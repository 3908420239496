import React, { useState, useEffect } from 'react';
import './notification-toast.css';

const NotificationToast = ({ message, type }) => {
    const getClassNames = () => {
        let classNames = 'notification-toast';
        classNames += ' show';
        classNames += type === 'error' ? ' error' : ' success';
        return classNames;
    };
    return (
        <div className={getClassNames()}>
            <p>{message}</p>
        </div>
    );
};

export default NotificationToast;