import React, { useState, useEffect } from 'react';
import SubscribeForm from '../components/subscribe-form/subscribe-form'
import Tiles from '../components/tiles/tiles';
import AnimatedSvgLogo from '../components/background-svg/animated-svg-logo';

function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}

const Home = () => {
    const [activeSection, setActiveSection] = useState('home');
    const [upNav, setUpNav] = useState('');
    const [downNav, setDownNav] = useState('');
    const articlesData = [
        {
            title: 'Generative AI: A catalyst for change or historical amnesia (Software Engineering)',
            description: 'Let’s focus on how AI is changing how we do software engineering. Google, StackOverflow, and annoying your seniors are fond memories of any aged engineer.',
            imageUrl: '/1708081404250.jpeg',
            url: 'https://www.linkedin.com/pulse/generative-ai-catalyst-change-historical-amnesia-software-hathorn-luh0f?trackingId=2VLgltEHTViCBD%2FEcKf7fQ%3D%3D',
            smallDescription: 'Published on Feb 16, 2024 • 4 min read',
        },
        {
            title: '🖌️ Exploring the Art of Software 🖥️',
            description: 'An analogy I would like to “paint” today is software can be a lot like painting. These similarities have proven to be more painful than it…',
            imageUrl: '/newsletters/art.png',
            url: 'https://medium.com/@hbhhathorn/software-like-painting-3e1c1b6e1616',
            smallDescription: 'Published on Sep 1, 2023 • 3 min read',
        },
        {
            title: 'Integration Tests - Postman vs C#',
            description: 'So, should we use friendly user GUI test interfaces for non-coding people or should we write happy code?',
            imageUrl: '/newsletters/csharp.png',
            url: 'https://medium.com/@hbhhathorn/integration-tests-postman-vs-c-5891f3e6197f',
            smallDescription: 'Published on May 26, 2023 • 3 min read',
        },
        {
            title: 'ChatGpt will replace jobs soon- Software Development jobs soon will be deprecated (for mindless…',
            description: 'Is anyone else being spammed with clickbait titles like this?',
            imageUrl: '/newsletters/gpt.png',
            url: 'https://medium.com/aimonks/chatgpt-will-replace-jobs-soon-software-development-jobs-soon-will-be-deprecated-for-mindless-275532e6da17',
            smallDescription: 'Published on May 8, 2023 • 3 min read • In 𝐀𝐈 𝐦𝐨𝐧𝐤𝐬.𝐢𝐨',
        },
        {
            title: 'How to deploy ASP.NET 6.0 Core Web API to AWS Lambda Easily',
            description: 'These steps are needed to deploy a new ASP.NET 6.0 Web API using controllers to AWS Lambda. The startup.cs file has been removed in .Net…',
            imageUrl: '/newsletters/deploy-lambda.png',
            url: 'https://medium.com/@hbhhathorn/how-to-deploy-asp-net-6-0-core-web-api-to-aws-lambda-easily-87fcfd8782a',
            smallDescription: 'Published on Nov 18, 2022 • 3 min read',
        },
        {
            title: 'DevOps for Dummies — Part 2',
            description: 'Happy are the “Dummies” because they always have space to learn. DevOps is all about re-iterating, learning, and improving.',
            imageUrl: '/newsletters/devops-2.png',
            url: 'https://medium.com/@hbhhathorn/devops-for-dummies-part-2-f87d45157638',
            smallDescription: 'Published on Nov 14, 2022 • 3 min read',
        },
        {
            title: 'DevOps for Dummies — Part 1',
            description: 'DevOps brings all parties involved in software delivery into coherence and glues them together with computer automation. That is teamwork!',
            imageUrl: '/newsletters/devops-part-1.png',
            url: 'https://medium.com/@hbhhathorn/devops-for-dummies-part-1-181671f6b0f2',
            smallDescription: 'Published on Oct 25, 2022 • 3 min read',
        },
        {
            title: 'Create an ASP.NET Core 3.0 Angular SPA web application with Docker support',
            description: 'With great jubilee we can now create ASP.NET Core web application with an Angular template. Now lets add docker support to!',
            imageUrl: '/newsletters/angular.png',
            url: 'https://medium.com/@hbhhathorn/create-an-asp-net-core-3-0-angular-spa-web-application-with-docker-support-86e8c15796aa',
            smallDescription: 'Published on Nov 14, 2019 • 3 min read • In The Startup',
        },
        {
            title: 'Authorize with JWT in ASP.NET Core 3.0 Web API',
            description: 'ASP.NET Core 3.0 provides native support that allows you to authorize endpoints using JSON web Tokens (JWT). Authorize web api with JWT',
            imageUrl: '/newsletters/auth.png',
            url: 'https://medium.com/@hbhhathorn/authorize-with-jwt-in-asp-net-core-3-0-web-api-542d7a10e8f5',
            smallDescription: 'Published on Nov 6, 2019 • 3 min read',
        }
    ];

    const portfolioData = [
        {
            title: 'Kepler & Co',
            description: 'I lead the architectural design and development of software products at Kepler + Co. I review code daily for adherence to designs, coding standards, and best practices. I provide mentorship, technical guidance, and support to junior and mid-level engineers.',
            imageUrl: '/portfolio/kepler.png',
            url: 'https://keplerco.io/',
        },
        {
            title: 'WesBank',
            description: 'I developed internal and public-facing applications and websites for WesBank, this included a platform for their internal processes of vehicle and insurance financing between multiple branches and car dealerships.',
            imageUrl: '/portfolio/wesbank.png',
            url: 'https://www.wesbank.co.za/',
        },
        {
            title: 'BankServAfrica',
            description: 'I built and deployed BankServAfrica\'s websites, article hub and intranet platform for internal staff and events. I followed rigorous security measures for all kubernetes deployments on their internal servers.',
            imageUrl: '/portfolio/bankservafrica.png',
            url: 'https://www.bankservafrica.com/website/',
        },
        {
            title: 'Ib’umdlali',
            description: 'I developed an Ionic app powered by a .NET Core API and DynamoDB on AWS, this app achieved a prestigious gold assegai award.',
            imageUrl: '/portfolio/ibumdlali.png',
            url: 'https://play.google.com/store/apps/details?id=io.onepointfour.ibumdlali.prod&hl=en_ZA&gl=US',
        },
        {
            title: 'Adbase',
            description: 'I built the Adbase system for managing tasks, creating briefs, adding project members, sharing media, communicating, tracking time, generating reports, and billing clients. One instance has logged 31500 hours of billable work.',
            imageUrl: '/portfolio/adbase.png',
            url: 'https://www.youtube.com/watch?v=QUFO1IN9F5A',
        },
        {
            title: 'Hupu',
            description: 'I was part of the team at Nologo Studios which developed a native Android and iOS app for artisinal trades to sell and deliver goods. I created CMS screens, public website, and backend systems using ASP.NET MVC, Webforms, Web API, and MS-SQL.',
            imageUrl: '/portfolio/hupu.png',
            url: 'https://www.hupu.co.za/',
        },
        {
            title: 'Belgotex',
            description: 'I built a .NET Core website for Belgotex, including Angular screens for the Admin CMS and a Web API. I completed the project in an agile fashion with sprint planning and demos.',
            imageUrl: '/portfolio/belgotex.png',
            url: 'http://www.belgotex.co.za',
        },
        {
            title: 'Zebbies',
            description: 'I built a custom API engine and frontend MVC platform for Zebbies, including user authentication and custom classes for http requests, API controllers, string helpers, etc. I deployed it on their internal servers with rigorous security measures.',
            imageUrl: '/portfolio/zebbies.png',
            url: 'https://www.zebbies.com/',
        },
        {
            title: 'Dunlop Tyres',
            description: 'I updated the Dunlop Tyres website with their request a quote functionality. I built internal systems for managing stocks, stores, dealers, sales, staff, etc. I used test-driven development (TDD) for building features.',
            imageUrl: '/portfolio/dunlop.png',
            url: 'http://www.dunloptyres.co.za/',
        },
        {
            title: 'Verigreen',
            description: 'I developed an ASP.NET Webform e-commerce site with a C# session-based cart system. I explored improvements such as persisting carts in a SQL table for scalability and reliability.',
            imageUrl: '/portfolio/verigreen.png',
            url: 'http://www.verigreen.co.za/',
        },
        {
            title: 'Ontap',
            description: 'I built the Ontap website and CMS using .NET Core MVC and Web API. I implemented a scheduled task for data scraping from a third-party API and storing it in a SQL database.',
            imageUrl: '/portfolio/ontap.png',
            url: 'https://www.ontap.co.za/',
        },
        {
            title: 'Snow Flake',
            description: 'I executed the Snowflake website project within a tight deadline, including CMS, website, and user authentication. I developed backend functionalities for recipe uploads and parsing data from Word documents.',
            imageUrl: '/portfolio/snowflake.png',
            url: 'https://www.snowflake.co.za/home',
        },
        {
            title: 'Burble',
            description: 'I integrated with the Burble website, creating a SQL database, .NET Core Web API, and Angular screens for admin CMS functionalities. I hosted the system in Azure for scalability and reliability.',
            imageUrl: '/portfolio/burble.png',
            url: 'http://www.burble.co.za/gift-voucher',
        },
    ];
    ;
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.section');
            let currentSectionId = '';
            sections.forEach(section => {
                const sectionTop = section.offsetTop - 200;
                const sectionHeight = section.clientHeight;
                if (window.scrollY >= sectionTop && window.scrollY < sectionTop + sectionHeight) {
                    currentSectionId = section.id;
                }
            });
            changeSection(currentSectionId);
        };
        const debouncedHandleScroll = debounce(handleScroll, 100);
        window.addEventListener('scroll', debouncedHandleScroll);
        return () => {
            window.removeEventListener('scroll', debouncedHandleScroll);
        };
    }, []);

    const changeSection = (section) => {
        setActiveSection(section);
        const sections = ['home', 'newsletters', 'about', 'contact'];
        const currentIndex = sections.indexOf(section);
        if (currentIndex > 0) {
            setUpNav(sections[currentIndex - 1]);
        } else {
            setUpNav('');
        }
        if (currentIndex < sections.length - 1) {
            setDownNav(sections[currentIndex + 1]);
        } else {
            setDownNav('');
        }
    };

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <div className='hide-sm'>
                <div className="top scroller">
                    {upNav && <>
                        <a onClick={() => scrollToSection(upNav)}><span class="hide-sm">{upNav} &nbsp;</span>
                            <object className="arrow fade-in" data="/up-arrow.svg" type="image/svg+xml">
                            </object>
                        </a>
                    </>}
                </div>
                <div className="bottom scroller">{downNav &&
                    <> <a onClick={() => scrollToSection(downNav)}><span class="hide-sm">{downNav} &nbsp;</span>
                        <object className="arrow" data="/down-arrow.svg" type="image/svg+xml">
                        </object>
                    </a>
                    </>}
                </div>
            </div>
            <section className="section center" id="home">
                <div class="row verticle">
                    <AnimatedSvgLogo className="logo"></AnimatedSvgLogo>
                    <h1> Harry Talks Tech</h1>
                    <div className="row">
                        <ul>
                            {/* Add onClick events to trigger smooth scrolling */}
                            <li><a onClick={() => scrollToSection('newsletters')}>Newsletters</a></li>
                            {/* <li><a onClick={() => scrollToSection('video-series')}>Video Series</a></li> */}
                            <li><a onClick={() => scrollToSection('about')}>About Me</a></li>
                            <li><a onClick={() => scrollToSection('contact')}>Get in Touch</a></li>
                        </ul>
                    </div>
                    <SubscribeForm></SubscribeForm>
                </div>
                <top-nav></top-nav>
                <bottom-nav></bottom-nav>
            </section>

            <section id="newsletters" className="section">
                <h2>Newsletters</h2>
                <Tiles tiles={articlesData}></Tiles>
            </section>
            {/* 
            <section id="video-series" className="section">
                <h2>Video Series Section</h2>
            </section> */}

            <section id="about" className="section">
                <h2>About Me</h2>
                <div class="info-block">
                    <div class="row">
                        <div class="show-sm"><img src='/pfp.png' /></div>
                        <div>
                            <p>I am a seasoned Software Engineer with a passion for designing and building scalable software architecture. My strong technical background and expertise in Test Driven Development, Observability-Driven Development, Software Design Patterns, and more, enable me to bring a wealth of knowledge and experience to every project.</p>
                            <p>As a Senior Software Engineer at Kepler + Co, I've had the opportunity to lead teams in designing high-quality software architecture and providing technical mentorship. My ability to identify problems, devise innovative solutions, and collaborate effectively with stakeholders has been instrumental in driving successful projects.</p>
                            <p>I have achieved technical mastery over skills such as C#, Asp.Net, NodeJs, Typescript, AWS, Redis, RabbitMQ, Grafana, SQL, Angular, ReactJs, Kubernetes, Docker, and CI/CD.</p>
                            <p>Driven by my love for technology and a desire to make a positive impact, I am dedicated to continuous learning and staying updated with the latest industry trends. My focus on collective success and fostering a culture of collaboration and improvement reflects my commitment to excellence in software engineering.</p>
                        </div>
                        <div class="hide-sm"><img src='/pfp.png' /></div>
                    </div>
                    <h3>Some of my projects:</h3>
                </div>
                <div class="portfolio-section">
                    <Tiles tiles={portfolioData}></Tiles>
                </div>
            </section>
            <section id="contact" className="section">
                <h2>Let's Get in Touch</h2>
                <div class="row">
                    <a href="https://github.com/harry-hathorn" target="_blank"><img class="social" src="/socials/github.png" />         </a>
                    <a href="https://www.linkedin.com/in/harry-hathorn/" target="_blank"><img class="social" src="/socials/linkedin.png" />       </a>
                    <a href="https://stackoverflow.com/users/7619230/harry" target="_blank"><img class="social" src="/socials/stackoverflow.png" />  </a>
                    <a href="mailto:hbhhathorn@gmail.com">
                        <img class="social" src="/socials/email.png" alt="Email icon" />
                    </a>
                </div>
                <br />
                <SubscribeForm></SubscribeForm>
            </section>
        </>
    );
};

export default Home;