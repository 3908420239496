import React, { useState } from 'react';
import NotificationToast from '../notification-toast/notification-toast'
import './styles.module.css';

const SubscribeForm = () => {
  const [email, setEmail] = useState('');
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  const handleSubscribe = async () => {
    try {
      const response = await fetch('https://sqz5dq55h7.execute-api.eu-west-1.amazonaws.com/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        setShowSuccessToast(true);
        setShowErrorToast(false);
      } else {
        setShowSuccessToast(false);
        setShowErrorToast(true);
      }
    } catch (error) {
      setShowSuccessToast(false);
      setShowErrorToast(true);
    }

    setTimeout(() => {
      setShowSuccessToast(false);
      setShowErrorToast(false);
    }, 4000);
  };
  return (
    <>
      <div class="info-block row verticle">
        <p>
          Elevate your <b>skills</b>, stay ahead in <b>tech</b>, and chart your path to <b>success</b>.
        </p>
        <p>
          Subscribe today and upgrade your career journey!
        </p>
        <br />
        {showSuccessToast && (
          <NotificationToast
            message="Thank you for subscribing 😄"
            type="success"
          />
        )}
        {showErrorToast && (
          <NotificationToast
            message="Please try again later."
            type="error"
          />
        )}
      </div>
      <form onSubmit={(e) => { e.preventDefault(); handleSubscribe(); }}>
       <button className='hide-sm' type="submit">Subscribe</button>
       
        <input type="email"
          placeholder="email@example.com"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}></input>
          <button className='show-sm' type="submit">Subscribe</button>
      </form>

      <br />
      <a class="tiny" href="/privacy" target='_blank'>Privacy statement</a>
    </>
  );
};

export default SubscribeForm;