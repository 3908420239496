import React from 'react';
import './background-svg.css';
import AnimatedSvgLogo from './animated-svg-logo';

const BackgroundSvg = () => {
  return (
    <div className="svg-outer-container">
      <AnimatedSvgLogo className="svg-container"></AnimatedSvgLogo>
    </div>
  );
};

export default BackgroundSvg;