import React from 'react';
import { Link } from 'react-router-dom';
import UnSubscribeForm from '../components/unsubscribe-form/unsubscribe-form'

const Unsubscribe = () => {
    return (
        <div class="info-block">
            <Link to="/">Take me home</Link>
            <UnSubscribeForm></UnSubscribeForm>
        </div>
    );
};

export default Unsubscribe;