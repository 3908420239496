import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css';
import BackgroundSvg from './components/background-svg/background-svg';
import Home from './pages/home';
import Privacy from './pages/privacy';
import NotFound from './pages/notfound';
import Unsubscribe from './pages/unsubscribe';

function App() {
  return (
    <Router>
      <div className="App">
        <div className="wrapper">
          <span className="hide-sm">
            <BackgroundSvg></BackgroundSvg>
          </span>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/unsubscribe" element={<Unsubscribe />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div ></div>
    </Router>
  );
}

export default App;
