import React, { useState, useEffect } from 'react';
import NotificationToast from '../notification-toast/notification-toast'
import '../subscribe-form/styles.module.css';

const UnSubscribeForm = () => {
  const [email, setEmail] = useState('');
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get('email');
    if (emailParam !== null) {
      unsubscribe(emailParam).then(x => console.log(x));
    }
  }, []);
  const unsubscribe = async (inputEmail) => {
    try {
      const response = await fetch('https://sqz5dq55h7.execute-api.eu-west-1.amazonaws.com/unsubscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: inputEmail }),
      });
      if (response.ok) {
        setShowSuccessToast(true);
        setShowErrorToast(false);
      } else {
        setShowSuccessToast(false);
        setShowErrorToast(true);
      }
    } catch (error) {
      setShowSuccessToast(false);
      setShowErrorToast(true);
    }
    setTimeout(() => {
      setShowErrorToast(false);
    }, 4000);

  }
  const handleSubscribe = async () => {
    await unsubscribe(email);
  };
  return (
    <div>
      {
        showSuccessToast &&
        <div>
          <p>You have successfully unsubscribed from the newsletter</p>
        </div>
      }
      {!showSuccessToast && <>
        <>
          <p>
            Unsubscribe from the newsletter.
          </p>
          <br />
          {showSuccessToast && (
            <NotificationToast
              message="You have successfully unsubscribed"
              type="success"
            />
          )}
          {showErrorToast && (
            <NotificationToast
              message="Failed to unsubscribe. Please try again later."
              type="error"
            />
          )}
        </>
        <form onSubmit={(e) => { e.preventDefault(); handleSubscribe(); }}>
          <button className='hide-sm' type="submit">Unsubscribe</button>
          <input type="email"
            placeholder="email@example.com"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}></input>
          <button className='show-sm' type="submit">Unsubscribe</button>
        </form>
        <br />
      </>
      }
    </div>
  );
};

export default UnSubscribeForm;