import React from 'react';
import './background-svg.css';

class AnimatedSvgLogo extends React.Component {
    render() {
        return <svg className={this.props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 100">
            <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="rgba(247, 214, 98, 0.8)" />
                    <stop offset="20%" stopColor="rgba(240, 66, 42, 1)" />
                    <stop offset="40%" stopColor="rgba(231, 79, 106, 1)" />
                    <stop offset="60%" stopColor="rgba(83, 208, 236, 0.8)" />
                    <stop offset="80%" stopColor="rgba(27, 49, 128, 1)" />
                    <stop offset="100%" stopColor="rgba(78, 173, 235, 1)" />
                </linearGradient>
            </defs>
            <path d="M0,0 V100 H50 V50 Q80,100 100,70 T150,50 V100 H200 V0 H150 V50 Q120,0 100,30 T50,50 V0 H0 Z"
                className="line" stroke="url(#gradient)" strokeWidth="5" strokeLinecap="round" rx="10" ry="10" />
            <path d="M0,0 V100 H50 V50 Q80,100 100,70 T150,50 V100 H200 V0 H150 V50 Q120,0 100,30 T50,50 V0 H0 Z"
                className="line-flipped" stroke="url(#gradient)" strokeWidth="5" strokeLinecap="round" rx="10" ry="10" />
        </svg>
    }
};

export default AnimatedSvgLogo;