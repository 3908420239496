import React from 'react';
import './tiles.css';

const Tiles = ({ tiles }) => {
  return (
    <div class="tiles">
      {tiles.map((tile, index) => (
        <div key={index} className="tile-inner">
          <a class="tile-link" href={tile.url} target="_blank" rel="noopener noreferrer">
            <div className="img-wrapper">
              <img src={tile.imageUrl} alt="Article Image" />
            </div>
            <h3>{tile.title}</h3>
            <p>{tile.description}</p>
            <p class="tile-footer"><small> {tile.smallDescription}</small></p>
          </a>
        </div>
      ))}
    </div>
  );
};

export default Tiles;