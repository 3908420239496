import React from 'react';
import { Link } from 'react-router-dom';

const Privacy = () => {
    return (
        <div class="info-block">
            <Link to="/">Take me home</Link>
            <h1>Privacy Agreement for Newsletter Subscribers</h1>

            <p>This Privacy Agreement ("Agreement") outlines how Harry Talks Tech ("we," "us," "our") collects, uses, and protects the personal information of individuals ("you," "your") who sign up for our weekly newsletter ("Newsletter") on <a class="no-link" href="https://harrytalks.tech/">harrytalks.tech</a>.</p>

            <h2>1. Information Collection:</h2>
            <p>When you subscribe to our Newsletter, we collect your email address. This information is securely stored and used solely for the purpose of sending you the Newsletter.</p>

            <h2>2. Use of Information:</h2>
            <p>We use your email address to send you our weekly Newsletter, which includes updates, announcements, promotions, and relevant content.</p>

            <h2>3. Data Security:</h2>
            <p>Your personal information is stored securely. We implement measures to protect your information from unauthorized access, use, or disclosure.</p>

            <h2>4. Email Delivery:</h2>
            <p>We use Amazon Simple Email Service (SES) to send our Newsletters. Amazon SES is a secure and reliable email delivery service.</p>

            <h2>5. Unsubscribe:</h2>
            <p>You have the right to unsubscribe from our Newsletter at any time. You can do so by clicking the "Unsubscribe" link provided in every Newsletter or by visiting  <Link class="no-link" to="/unsubscribe">harrytalks.tech/unsubscribe</Link>. This will also remove your email address from our secure storage.</p>

            <h2>6. Third-Party Disclosure:</h2>
            <p>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as required by law.</p>

            <h2>7. Updates to Privacy Policy:</h2>
            <p>We may update this Privacy Agreement from time to time. Any changes will be posted on <a class="no-link" href="https://harrytalks.tech/">harrytalks.tech</a>, and your continued use of the Newsletter constitutes your acceptance of the revised Agreement.</p>

            <p>By subscribing to our Newsletter, you agree to the terms of this Privacy Agreement.</p>

            <p>If you have any questions or concerns about our privacy practices, please contact us at harry@harrytalks.tech</p>
        </div>
    );
};

export default Privacy;